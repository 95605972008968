export const vueI18n = {"fallbackLocale":"nl","messages":{"nl":"~assets/locales/nl-NL.json"}}
export const vueI18nLoader = false
export const locales = [{"code":"nl","name":"NL","file":"nl-NL.json"}]
export const defaultLocale = null
export const routesNameSeparator = '___'
export const defaultLocaleRouteNameSuffix = 'default'
export const strategy = 'prefix_except_default'
export const lazy = true
export const langDir = './assets/locales/'
export const rootRedirect = null
export const detectBrowserLanguage = false
export const differentDomains = false
export const seo = false
export const baseUrl = ''
export const vuex = {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true}
export const parsePages = false
export const pages = {"index":{"nl":"/"},"blog/index":{"nl":"/blog"},"blog/_slug":{"nl":"/blog/:slug"},"vacancies/index":{"nl":"/vacatures"},"vacancies/_slug":{"nl":"/vacatures/:slug"},"services/index":{"nl":"/diensten"},"services/_slug":{"nl":"/diensten/:slug"},"about-us":{"nl":"/over-ons"},"privacy-statement":{"nl":"/privacy-statement"},"disclaimer":{"nl":"/disclaimer"},"contact":{"nl":"/contact"},"quality":{"nl":"/kwaliteit"}}
export const beforeLanguageSwitch = () => null
export const onLanguageSwitched = () => null
export const IS_UNIVERSAL_MODE = true
export const MODULE_NAME = 'nuxt-i18n'
export const LOCALE_CODE_KEY = 'code'
export const LOCALE_ISO_KEY = 'iso'
export const LOCALE_DOMAIN_KEY = 'domain'
export const LOCALE_FILE_KEY = 'file'
export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}
export const COMPONENT_OPTIONS_KEY = 'nuxtI18n'
export const localeCodes = ["nl"]
