export const strict = false

export const state = () => ({
  vacanciesActive: false,
  language: ''
})

export const getters = {
  getVacancyActivityState(state) {
    return state.vacanciesActive
  },
  getLanguage(state) {
    return state.language
  }
}

export const mutations = {
  SET_VACANCY_ACTIVITY_STATE(state, vacanciesActive) {
    state.vacanciesActive = vacanciesActive
  },
  SET_LANGUAGE(state, language) {
    state.language = language
  }
}

export const actions = {
  async nuxtServerInit({ commit }, { app, req, redirect }) {
    let language = req._parsedUrl.path.split('/')
    language.shift()
    language = language[0]

    if (language === '') {
      language = 'nl'
    } else if (language.length === 2) {
      app.$cookies.set('language', language, {
        path: '/',
        maxAge: 60 * 60 * 24 * 365
      })
    } else if (language.length < 2) {
      language = app.$cookies.get('language')
        ? app.$cookies.get('language')
        : req.headers['accept-language'].substring(0, 2)
    } else {
      language = app.$cookies.get('language')
        ? app.$cookies.get('language')
        : req.headers['accept-language'].substring(0, 2)
      redirect(`/${language}${req._parsedUrl.path}`)
    }

    if (req._parsedUrl.path.length < 2) {
      redirect(`/${language}`)
    }

    const vacanciesActive = await app.$axios.$get(`page/${language}/vacatures`)

    commit(
      'SET_VACANCY_ACTIVITY_STATE',
      vacanciesActive.acf ? vacanciesActive.acf.actief : false
    )
    commit('SET_LANGUAGE', language)
  },
  setLanguage({ commit }, language) {
    commit('SET_LANGUAGE', language)
  }
}
