import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d0c503fc = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _37dad0b6 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _17869c50 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _c5366d3e = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _0f33061b = () => interopDefault(import('../pages/disclaimer.vue' /* webpackChunkName: "pages/disclaimer" */))
const _188e9ca2 = () => interopDefault(import('../pages/quality.vue' /* webpackChunkName: "pages/quality" */))
const _5560bdde = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _70ae0a9a = () => interopDefault(import('../pages/privacy-statement.vue' /* webpackChunkName: "pages/privacy-statement" */))
const _46d023cc = () => interopDefault(import('../pages/vacancies/index.vue' /* webpackChunkName: "pages/vacancies/index" */))
const _3b481346 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _c8a3afce = () => interopDefault(import('../pages/services/_slug.vue' /* webpackChunkName: "pages/services/_slug" */))
const _45198284 = () => interopDefault(import('../pages/vacancies/_slug.vue' /* webpackChunkName: "pages/vacancies/_slug" */))
const _d432468c = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'active-link',
  linkExactActiveClass: 'exact-active-link',
  scrollBehavior,

  routes: [{
    path: "/nl",
    component: _d0c503fc,
    name: "index___nl"
  }, {
    path: "/nl/blog",
    component: _37dad0b6,
    name: "blog___nl"
  }, {
    path: "/nl/contact",
    component: _17869c50,
    name: "contact___nl"
  }, {
    path: "/nl/diensten",
    component: _c5366d3e,
    name: "services___nl"
  }, {
    path: "/nl/disclaimer",
    component: _0f33061b,
    name: "disclaimer___nl"
  }, {
    path: "/nl/kwaliteit",
    component: _188e9ca2,
    name: "quality___nl"
  }, {
    path: "/nl/over-ons",
    component: _5560bdde,
    name: "about-us___nl"
  }, {
    path: "/nl/privacy-statement",
    component: _70ae0a9a,
    name: "privacy-statement___nl"
  }, {
    path: "/nl/vacatures",
    component: _46d023cc,
    name: "vacancies___nl"
  }, {
    path: "/nl/blog/:slug",
    component: _3b481346,
    name: "blog-slug___nl"
  }, {
    path: "/nl/diensten/:slug",
    component: _c8a3afce,
    name: "services-slug___nl"
  }, {
    path: "/nl/vacatures/:slug",
    component: _45198284,
    name: "vacancies-slug___nl"
  }, {
    path: "/nl/:slug",
    component: _d432468c,
    name: "slug___nl"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
